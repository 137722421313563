module.exports = {
  siteAddress: new URL("https://petedemarte.com"),
  siteTitle: 'Pete DeMarte', // <title>
  manifestName: 'PeteDeMarte',
  manifestShortName: 'Pete', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#506997',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Pete DeMarte',
  subHeading: 'Web Developer',
  subHeading2: '',
  // social
  socialLinks: [
   /* {
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/pdemarte',
    },*/
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/pdemarte/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:pete@petedemarte.com',
    },
  ],
  paulMeise: {
    siteTitle: 'Paul Meise, ABR, GRI',
    siteAddress: new URL("http://paulmeise.com")
  }
};
